<template>
    <div class="bg-page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('充值')" left-arrow
                     @click-left="onClickLeft" @click-right="onClickRight">
            <!-- <template #right>
                <span style="max-width: 100px;color: #FFFFFF;">{{$t('充值说明')}}</span>
            </template> -->
        </van-nav-bar>
        <div class="wt-wrapper">
            <div class="quk-card">
                <div class="quk-item">
                    <div class="value">
                        <span class="value-lg">{{user.balance}}</span> <span class="text-gray" style="font-size: 12px">{{$t('USDT')}}</span>
                    </div>
                    <div class="label text-gray">{{$t('钱包余额')}}</div>
                </div>
                <div class="quk-item" v-if="user.huilv > 0 && !isUsdt">
                    <div class="value">{{user.huilv}}</div>
                    <div class="label text-gray">{{$t('汇率')}}</div>
                </div>
            </div>
            <div class="wt-panel bs-panel desp-lists">
                <div v-if="selectItem" class="current-desp-cell">
                    <div class="text-red">{{selectItem.title}}</div>
                    <div class="item-btn text-gray" @click="show_channel = true">{{$t('重新选择')}}</div>
                </div>
                <div class="wt-input_items  u-m-b10">
                    <div class="hd u-m-b10">{{$t('金额')}}
                        (
                            {{$t('USDT')}}
                        )
                    </div>
                    <van-field v-if="isUsdt" v-model="money" :placeholder="$t('请输入充值金额')" @input="changeMoney2" type="number"/>
                    <van-field v-else v-model="money" :placeholder="$t('请输入充值金额')" @input="changeMoney2" type="digit"/>
                </div>
                <div class="tri-cell">
                    <div class="text-gray" style="font-size: 14px" v-if="real_money > 0">
                        <div>
                            <span class="v-label">{{$t('充值金额')}}：</span>
                            <span class="lg-val" v-if="isUsdt">
                                {{money}} <span style="font-size: 12px">{{$t('USDT')}}</span>
                            </span>
                            <span class="lg-val" v-else>
                                1USDT = 1USD
                            </span>
                        </div>
                    </div>
                    <div class="desp-card-list">
                        <template v-for="(item, index) in robot_list">
                            <div class="desp-cell"
                                 v-if="item.level > 0"
                                 :key="index"
                                 @click="changeMoney(parseFloat(parseFloat(item.price) + parseFloat(item.min_money)))"
                            >
                                <div class="flex_bd">
                                    <div class="rc-title">{{$t(item.name)}} {{$t('价格')}}
                                        <span class="text-red">{{parseFloat(item.price)}} </span>
                                        <span style="padding-left:5px;"> {{$t('USDT')}}</span>
                                    </div>
                                    <div class="text-gray">{{$t('启动资金')}}
                                        <span class="text-red">{{parseFloat(item.min_money)}} </span>
                                        <span style="padding-left:5px;"> {{$t('USDT')}}</span>
                                    </div>
                                </div>
                                <div class="van-button van-button--info van-button--normal">
                                    <strong>{{parseFloat(parseFloat(item.price) + parseFloat(item.min_money))}} </strong>{{$t('USDT')}}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="btn-space">
                <van-button color="linear-gradient(to right, #3270e0, #3653ea)" block @click="recharge">{{$t('确认充值')}}</van-button>
            </div>
        </div>
        <van-popup v-model="show_channel" position="bottom" :close-on-click-overlay="false">
            <div style="font-size:16px; padding:20px 0; text-align:center;">
                {{$t('请选择充值方式')}}
            </div>
            <div class="wt-panel wt-list-cells">
                <van-radio-group v-model="pay_type">
                    <van-cell-group>
                        <template  v-for="(item, index) in scene">
                        <van-cell v-if="item.title == 'TRC20'"
                                  :title="item.title"
                                  :key="index"
                                  center
                                  clickable
                                  @click="handleClickSelectRecharge(item)"
                        >
                            <template #icon>
                                <img v-if="item.channel == 'local_usdt' || item.method == 'usdt'" src="../../assets/pictures/USDT.png"
                                     class="wt-icon"
                                     alt="">
                                <img v-else src="../../assets/pictures/bank-ico.png" class="wt-icon" alt="">
                            </template>
                            <template #title>
                                {{item.title}}
                            </template>
                            <template #right-icon>
                                <van-radio :name="item.key">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </template>
                    </van-cell-group>
                </van-radio-group>
            </div>
        </van-popup>

        <!-- url付款链接 -->
        <van-popup :overlay="true" position="bottom"
                   closeable close-icon="cross"
                   v-model="showPayPage"
                   @close="payPageClosed()"
                   :style="{ height: '100%' }"
        >
            <div style="font-size:16px; padding:12px 0; text-align:center;background-color: #2c384a; color: #fff">
                {{$t('充值中心')}}
                <van-loading v-if="!payPageLoaded"/>
            </div>
            <div style="text-align: left; height:100%;">
                <iframe :src="payUrl" width="100%" height="100%" frameborder="0" @load="payLoaded"
                        :style="{backgroundColor: payPageLoaded ? '#FFFFFF': 'unset' }"></iframe>
            </div>
        </van-popup>

        <van-popup :overlay="false" position="bottom"
                   closeable
                   v-model="showBankPage"
                   :style="{ height: '100%' }"
        >
            <div class="wrapper">
                <template v-if="rechargeBank">
                    <div class="p-title-label" style="padding-top: 30px">{{$t('银行卡信息')}}</div>
                    <van-form class="bs-form">
                        <van-field readonly v-model="rechargeBank.bank" class="theme_5">
                            <template #label><span class="text-green">{{$t('银行名称')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.bank" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="rechargeBank.name" >
                            <template #label><span class="text-green">{{$t('持卡人姓名')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.name" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="rechargeBank.number" >
                            <template #label><span class="text-green">{{$t('银行卡号')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.number" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="rechargeBank.money" >
                            <template #label><span class="text-green">{{$t('充值金额')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.money" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="real_money"  v-if="real_money > 0">
                            <template #label>
                                <span class="text-green">{{$t('到账金额')}}</span></template>
                            <template #input>
                                <span class="theme_5">{{real_money}}</span> <span style="font-size: 12px;padding-left: 10px;color: #c8c9cc"> 
                                    {{$t('USDT')}}
                                </span>
                            </template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="real_money" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field v-if="real_money > 0" type="textarea" rows="3" :label="$t('充值提示')" disabled >
                            <template #input>
                                <div v-html="$t('充值提示1')" style="color: #c8c9cc"></div>
                            </template>
                        </van-field>
                        <div class="p-upload" style="margin-left: 10px">
                            <van-uploader v-model="fileList" deletable :max-count="1"
                                          :after-read="afterRead"/>
                        </div>
                        <div class="p-footer">
                            <van-button class="queren" round block type="primary" :disabled="loading" @click="handleClickSave">
                                {{$t('保存并提交')}}
                            </van-button>
                        </div>
                    </van-form>
                </template>
                <template v-else>
                    <div>
                        <van-empty :description="$t('暂无银行渠道')"/>
                    </div>
                </template>
            </div>
        </van-popup>

        <!-- USDT收款 -->
        <van-popup :overlay="false" position="bottom"
                   closeable close-icon="cross"
                   v-model="showUsdtPage"
                   :style="{ height: '100%'}"
        >
            <template v-if="usdt_address">
                <div class="wt-page">
                    <div class="wt-page-hd">
                       {{  $t('充值') }}
                    </div>
                    <div class="wt-first">
                        <div class="current-desp-cell">
                            {{ $t('USDT') }}
                        </div>
                        <div class="bs-x u-m-b15">
                            <div class="inc-tabs">
                                <van-tabs class="dep-usdt-tabs" v-model="usdt_active" @rendered="onChangeUsdtType">
                                    <van-tab v-for="(address, type) in usdt_address" :key="`type${type}`" :title="type">
                                        <template #title>
                                            <div class="text">{{type}}</div>
                                        </template>
                                        <div class="mabox">
                                            <div style="text-align: center">
                                                <div class="tk-wx" :id="`usdtQrcode${type}`"></div>
                                                <div class="code-value"
                                                     style="padding-bottom: 10px;font-size: 14px;text-align: center">
                                                    {{address}}
                                                </div>
                                                <van-button :data-clipboard-text="address"  data-clipboard-action="copy" @click="copy('.clipboardBtn')" color="linear-gradient(to right, #fb5b5b, #ed4545)" round
                                                            size="small" style="min-width: 80px"
                                                            class="clipboardBtn btn-copy">
                                                    {{$t('复制')}}
                                                </van-button>
                                            </div>
                                        </div>
                                    </van-tab>
                                </van-tabs>
                            </div>
                        </div>
                    </div>
                    <div class="wt-second">
                        <div class="bs-x" v-if="real_money > 0">
                            <div class="usdt-card">
                                <span class="text-7a8">{{$t('到账金额')}}</span>
                                <br/>
                                <span class="text-251 font32">{{real_money}}</span>
                                <span class="text-251"> {{$t('USDT')}} </span>
                            </div>
                            <div class="usdt-body">
                                <!-- <div class="mabox-tip text-gray">
                                    <div class="tt">{{$t('USDT充值提示')}}</div>
                                    <div class="tips" v-html="$t('USDT充值提示1')"></div>
                                </div> -->
                                <div class="wt-input_items u-m-b15">
                                    <van-form ref="formData" class="">
                                        <div class="w-usdt-hd u-m-b10">{{$t('打款钱包地址')}}</div>
                                        <van-field class="w-usdt" v-model="pay_account" :placeholder="$t('请输入打款钱包地址')"></van-field>
                                    </van-form>
                                </div>
                                <div class="p-upload mrt15">
                                    <van-uploader v-model="fileList" deletable :max-count="1"
                                                  :after-read="afterRead"/>
                                </div>
                            </div>
                        </div>
                        <div class="mabox-space">
                            <van-button color="linear-gradient(to right, #3270e0, #3653ea)" :disabled="loading" @click="handleClickSave(1)" block>{{$t('保存并提交')}}
                            </van-button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <van-empty :description="$t('暂无USDT收款地址')"/>
                </div>
            </template>
        </van-popup>

        <van-popup v-model="show_tips" position="center"
                   :close-on-click-overlay="false"
                   style="background-color: #EDF4F1;width: 90%;min-height: 200px;border-radius: 10px;">
            <div style="padding:20px 20px 0 20px">
                <div style="font-size: 14px;font-weight: bold">{{$t('TIPS')}}:</div>
            </div>
            <div style="padding: 0 20px;font-size: 12px;line-height: 18px" v-html="tips_item.tips">
            </div>
            <div style="padding:20px;text-align: right">
                <van-button @click="rechare_tips" type="primary" round>{{$t('确认充值')}}</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";

    export default {
        components: {},
        data() {
            return {
                show_channel: true,
                show_tips:false,
                tips_item:{},
                select_usdt: false,
                curr: -1,
                money: '',
                money_list: [300, 5000, 10000, 20000, 50000, 100000],
                activeIcon: require('../../assets/pictures/w-checked.png'),
                inactiveIcon: require('../../assets/pictures/w-check.png'),
                scene: [],
                user: {},
                real_money: 0,
                loading: false,
                fileList: [],
                showBankPage: false,
                isUsdt: false,
                usdt_active: 0,
                showUsdtPage: false,
                usdt_address: {
                    erc: '6473892fdjknf',
                    omni: '3523523432'
                },
                showKmPage: false,
                rechargeBank: {},
                showPayPage: false,
                payUrl: "",
                file: "",
                selectItem: "",
                pay_type: "bank",
                payPageLoaded: false,
                loadingUser: false,
                qecode: {},
                pay_account: '',
                pay_mobile: '',
                robot_list:[],
            }
        },
        methods: {
            onClickRight() {
                this.$router.push({path: '/recharge'})
            },
            copy(val) {
                console.log(val);
                let clipboard = new Clipboard(val ? val : ".clipboardBtn");
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    this.$toast.success(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            onChangeUsdtType(e, type) {
                this.genQrcode('usdtQrcode' + type, this.usdt_address[type])
            },
            genQrcode(id, address) {
                let url = address;
                if (this.qecode[id]) {
                    this.qecode[id].clear();
                    this.qecode[id].makeCode(url);
                    setTimeout(() => {
                        let sq = window.document.getElementById(id);
                        let img = sq.querySelector('img');
                        let canvas = sq.querySelector('canvas');
                        if (img && canvas) {
                            img.style = "display:none !important";
                            canvas.style = "display:block !important";
                        }
                    }, 50);
                    return;
                }
                this.qecode[id] = new QRCode(id, {
                    text: url,
                    typeNumber: 17,
                    width: 120,
                    height: 120,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
                setTimeout(() => {
                    let sq = window.document.getElementById(id);
                    let img = sq.querySelector('img');
                    let canvas = sq.querySelector('canvas');
                    if (img && canvas) {
                        img.style = "display:none !important";
                        canvas.style = "display:block !important";
                    }
                }, 50);
            },
            payLoaded() {
                this.payPageLoaded = true;
            },
            payPageClosed() {
                console.log("payPageClosed");
                this.payUrl = "";
                this.payPageLoaded = false;
            },

            afterRead(file, ext) {
                this.file = file.file;
                // helper.compressImg(file.file, (data) => {
                //     this.file = helper.dataURLtoFile(data);
                // });
            },
            handleClickSelectRecharge(item) {
                this.pay_type = item.key;
                this.selectItem = item;
                if (item.channel == 'local_usdt' || item.code == 'TRC20' || item.method == 'usdt') {
                    if (this.isUsdt != true) {
                        this.money = (this.money / this.user.huilv).toFixed(0);
                    }
                    this.real_money = this.money;
                    this.isUsdt = true;
                } else {
                    if (this.isUsdt == true) {
                        this.money = Math.ceil(this.money * this.user.huilv).toFixed(0);
                    }
                    this.isUsdt = false;
                    this.changeMoney2()
                }
                this.show_channel = false;
            },
            onClickLeft() {
                this.$router.push({path: '/'});
                // this.$router.go(-1);
            },
            changeMoney(money) {
                if (this.user.huilv > 0 && !this.isUsdt) {
                    this.money = Math.ceil(money * this.user.huilv).toFixed(0);
                    this.real_money = (this.money / this.user.huilv).toFixed(2);
                } else {
                    this.money = money;
                    this.real_money = this.money;
                }
            },
            changeMoney2(index) {
                this.real_money = this.user.huilv > 0 && !this.isUsdt ? (this.money / this.user.huilv).toFixed(2) : this.money;
            },
            getData() {
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                        this.money = res.data.r_default;
                        this.changeMoney2();
                    })
            },
            rechare_tips(){
                this.recharge();
                this.show_tips = false;
            },
            recharge() {
                if (this.money == undefined || this.money < 0 || this.money == '' || this.money.trim() == '') {
                    this.$toast.fail(this.$t('请输入充值金额'));
                    return;
                }
                let item = this.selectItem;
                if (typeof item == "object") {
                    if(this.show_tips == false && (item.tips != '' || item.tips.trim() != '')){
                        this.show_tips = true;
                        this.tips_item = item;
                        return ;
                    }
                    if (item.channel == "local_bank" || item.channel == "local_wechat" || item.channel == "local_alipay") {
                        this.handleClickShowBank(item.setting, item);
                        return;
                    }else if (item.channel == "local_usdt") {
                        this.handleClickShowUsdt(item.setting, item);
                        return;
                    }
                } else {
                    this.$toast.fail(this.$t('请选择充值方式'));
                    return;
                }
                this.doRecharge()
            },
            doRecharge() {
                if (this.money == undefined || this.money < 0 || this.money == '' || this.money.trim() == '') {
                    this.$toast.fail(this.$t('请输入充值金额'));
                    return;
                }
                let param = new FormData();
                if (this.select_usdt == true) {
                    param.append("money", this.real_money);
                } else {
                    param.append("money", this.money);
                }
                param.append("file", this.file);
                param.append("pay_account", this.pay_account);
                param.append("pay_mobile", this.pay_mobile);
                param.append("pay_type", this.pay_type);

                // var tempwindow = null;
                let loading = this.$toast.loading(this.$t('加载中'));

                this.$axios
                    .post(this.$api_url.recharge, param, {
                        headers: {"Content-Type": "multipart/form-data"},
                    })
                    .then((responese) => {
                        let res = responese.data;
                        console.log(res,'resresres')
                        if (res.code === 0) {
                            let data = res.data;
                            if (!data.pay_url) {
                                this.$toast.success(res.msg);
                                return;
                            }
                            if(data.target_type == 0){
                                this.payUrl = data.pay_url;
                                this.showPayPage = true;
                                this.payPageLoaded = false;
                                loading.clear();
                            }else if(data.target_type == 1){
                                loading.clear();
                                let deviceAgent = navigator.userAgent;
                                let ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
                                if(ios){
                                    document.location.href = data.pay_url;
                                }else{
                                    window.open(data.pay_url, '_blank');
                                }
                            }
                        } else {
                            console.log(res)
                            this.$toast.fail(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                        loading.clear();
                    }).then(() => {
                    this.showBankPage = false;
                    this.showUsdtPage = false;
                });
            },
            handleClickSave(f) {
                if (f == 1) {
                    if (this.pay_account == '') {
                        this.$toast.fail(this.$t('请输入打款钱包地址'));
                        return;
                    }
                    this.select_usdt = true;
                }
                if (this.file) {
                    this.doRecharge()
                } else {
                    this.$toast.fail(this.$t('请添加截图'));
                }
            },
            handleClickShowBank(item, pitem) {
                if(pitem.min_amount > this.money){
                    this.$toast.fail(this.$t('最低充值金额为：{0}', [pitem.min_amount + '']));
                    return;
                }
                if(pitem.max_amount != 0 && pitem.max_amount < this.money){
                    this.$toast.fail(this.$t('最高充值金额为：{0}', [pitem.max_amount + '']));
                    return;
                }
                if (item) {
                    this.rechargeBank.number = item.bank_number;
                    this.rechargeBank.name = item.name;
                    this.rechargeBank.bank = item.bank_name;
                    this.rechargeBank.ifsc = item.ifsc;
                    this.rechargeBank.branch = item.branch;
                } else {
                    this.pay_type = "bank";
                }
                this.rechargeBank.money = this.money;
                this.showBankPage = true;
            },
            handleClickShowUsdt(item, pitem) {
                if (pitem.min_amount > this.money) {
                    this.$toast.fail(this.$t('最低充值金额为：{0}', [pitem.min_amount + '']));
                    return;
                }
                if (pitem.max_amount != 0 && pitem.max_amount < this.money) {
                    this.$toast.fail(this.$t('最高充值金额为：{0}', [pitem.max_amount + '']));
                    return;
                }
                this.showUsdtPage = true;
                this.usdt_address = item;

            },
            getScene() {
                this.$axios
                    .get(this.$api_url.get_recharge_scene + "?money=" + this.money)
                    .then((res) => {
                        if (res.data) {
                            this.scene = res.data.list;
                            this.robot_list = res.data.robot_list;

                            setTimeout(() => {
                                this.handleClickSelectRecharge(this.scene[0]);
                            }, 1000);
                            
                        }
                    });
            },
        },
        mounted() {
            this.getData();
            this.getScene();
            let recharge_default_amount = localStorage.getItem('app.recharge_default_amount');

            if (recharge_default_amount) {
                this.money_list = recharge_default_amount.split(',')
            }
        },
        watch:{
            money(n, o){
                this.money = n.replace("-", "");

            }
        }
    }
</script>

<style scoped>
    .desp-card-list{
        padding: 10px 0;
    }
    .bg-page{
        min-height: 100vh;
        background-color: #1a2331;
    }
    .bg-page ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .desp-lists{
        padding: 15px;
    }
    .quk-card{
        display: flex;
        align-items: center;
    }
    .icon-plus{
        max-width: 36px;
    }
    .wt-input_items .hd{
        font-size: 16px;
        font-weight: bold;
    }
    .tri-cellss{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .wt-list-cells  ::v-deep .van-cell-group{
        background-color: transparent;
    }
    .wt-input_items ::v-deep .van-field__control{
        color: #ffffff;
    }
    .wt-list-cells  ::v-deep .van-cell-group::after{
        display: none;
    }
    .wt-list-cells  ::v-deep .van-cell{
        background-color: #2c384a;
        border: 1px solid #757a7b;
        border-radius: 10px;
        margin-bottom: 15px;
        color: #ffffff;
    }
    .wt-list-cells  ::v-deep .van-cell::after{
        display: none;
    }
    .wt-input_items ::v-deep .van-cell{
        background-color: #2c384a;
        border-radius: 10px;
    }
    .wt-panel{
        background-color: #11161b;
        padding: 35px 20px 20px 20px;
        border-radius:  0 0 30px 30px;
        margin-top: -20px;
    }
    .wt-icon{
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }
    .img-icon{
        width: 20px;
        height: 20px;
    }
    .quk-card{
        position: relative;
        z-index: 22;
        justify-content: space-between;
        padding: 20px;
        font-size: 14px;
        border-radius: 0 0 30px 30px;
        background-color: #1a2331;
    }
    .lg-value{
        font-size: 20px;
        font-weight: bold;
    }
    .quk-card .label{
        margin-top: 10px;
    }
    .bank-pop .wt-top{
        font-size: 18px;
        padding: 15px;
    }
    .current-desp-cell{
        display: flex;
        border-radius: 10px;
        background-color: #2c384a;
        padding: 15px;
        margin-bottom: 15px;
    }
    .current-desp-cell .item-btn{
        margin-left: auto;
    }
    .desp-cell{
        display: flex;
        align-items: center;
        border-radius: 10px;
        background-color: #252f46;
        padding: 15px;
        margin-bottom: 10px;
    }
    .desp-cell:last-child{
        margin-bottom: 0;
    }
    .desp-cell .rc-title{
        margin-bottom: 5px;
    }
    .desp-cell .van-button{
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 0 8px;
        font-weight: normal;
        font-size: 12px;
    }
    .btn-space{
        padding:20px 10%;
    }
    .value-lg{
        font-weight: bold;
        font-size: 16px;
    }
    .wt-first{
        background-color: #1a2331;
        border-radius: 0 0 30px 30px;
        padding: 15px;
        position: relative;
        z-index: 23;
    }
    .wt-second{
        background-color: #11161b;
        font-size:14px;
        padding: 30px 15px 15px 15px;
        margin-top: -10px;
    }
    .wt-page-hd{
        background-color: #1a2331;
        padding: 15px;
        line-height: 20px;
        font-size: 16px;
    }
    .inc-tabs ::v-deep .van-tab__text{
        width: 100%;
        height: 44px;
        background-color: #2c2c2c;
    }
    .inc-tabs ::v-deep .van-tab--active .van-tab__text{
        background-color: #3653ea;
        color: #ffffff;
    }
    .mabox{
        padding: 30px 0;
        background: url(../../assets/pictures/trend-card.jpg) center no-repeat;
        background-size: cover;
        border-radius:20px;
    }
    .tk-wx{
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        width: 128px;
        height: 128px;
        margin-bottom: 20px;
    }
    .tk-wx img{
        width: 120px;
        height: 120px;
    }
    .mabox-space{
        padding: 20px 10%;
    }
    .usdt-card{
        padding: 15px 20px;
        border-bottom: 1px solid #313132;
    }
    .font32{
        font-size: 18px;
        font-weight: bold;
    }
    .text-7a8{
        margin-bottom: 5px;
    }
    .usdt-body{
        padding: 15px;
    }
    .mabox-tip{
        line-height: 1.8;
        margin-bottom: 10px;
        opacity: .6;
    }
    .p-upload ::v-deep .van-uploader__upload{
        background-color: #2c384a;
        border-radius: 6px;
    }









</style>